/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-03",
    versionChannel: "nightly",
    buildDate: "2024-04-03T00:06:39.509Z",
    commitHash: "6de12dd59c10e4a9aa1d34222a7e11c91d01a210",
};
